<template lang="pug">
    v-app-bar(app absolute flat dark color="primary" background-color="red" :height="$vuetify.breakpoint.smAndDown ? '270px' : '90px'"  min-width="200px" max-width="1200px").mx-auto
        v-row(dense align="center")
            v-col(cols="12" md="2").text-md-left
                router-link(to="/")
                    v-img(src="@/assets/logo_white_bg.png" contain height="80px" width="200px").mx-auto.mx-md-3

            v-col(cols="12" md="7")
                .d-flex.align-center.align-md-start.justify-center.justify-md-start.ml-md-6.flex-column.flex-md-row
                    v-btn(to="/student-guide" text).btn.font-weight-bold Student Guide
                    //- v-btn(to="/lessons" text).btn.font-weight-bold Lessons
                    LessonsMenu
                    ToolsMenu
                    //- GrammarMenu
                    //- GamesMenu
                    
                    .is-teacher(v-if="isTeacher && $vuetify.breakpoint.mdAndUp")
                        v-btn(v-if="isDev", to="/create-lesson" text).btn.font-weight-bold Create
                        v-btn(v-if="isDev", to="/init-lessons" text).btn.font-weight-bold Init
            v-col(cols="12" md="3").text-center.text-md-right
                Usermenu
</template>

<script>
import { get } from "vuex-pathify";
import Usermenu from "@/components/Nav/UserMenu.vue";
import ToolsMenu from "@/components/Nav/ToolsMenu.vue";
import LessonsMenu from "@/components/Nav/LessonsMenu.vue";
// import GrammarMenu from "@/components/Nav/GrammarMenu.vue";
// import GamesMenu from "@/components/Nav/GamesMenu.vue";

export default {
  name: "NavbarDesktop",
  components: { Usermenu, ToolsMenu, LessonsMenu },
  computed: { ...get({ isDev: "isDev", isTeacher: "Lessons/isTeacher" }) },
};
</script>
