import { initializeApp } from "firebase/app";
import {
  getFirestore,
  connectFirestoreEmulator,
  collection,
  getDocs,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";

import {
  getStorage,
  connectStorageEmulator,
  ref,
  getDownloadURL,
} from "firebase/storage";

import {
  getAuth,
  connectAuthEmulator,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";

import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";

const isDev = process.env.NODE_ENV !== "production";

// console.log("isDev = ", isDev);

const firebaseConfig = {
  apiKey: "AIzaSyDh3wqHRzRt_SdR2v3PUIBn0m5yJwnuDcA",
  authDomain: "sericity-v1.firebaseapp.com",
  projectId: "sericity-v1",
  storageBucket: "sericity-v1.appspot.com",
  messagingSenderId: "50448620066",
  appId: "1:50448620066:web:c091372cc33208f4e6887b",
};

initializeApp(firebaseConfig);

const db = getFirestore();
const auth = getAuth();
const storage = getStorage();
const functions = getFunctions();

const initEmulators = async () => {
  console.log("initializing connections to emulator suite");

  connectAuthEmulator(auth, "http://localhost:9099");
  connectFunctionsEmulator(functions, "localhost", 5001);
  connectFirestoreEmulator(db, "localhost", 8089);
  connectStorageEmulator(storage, "localhost", 9199);
};

if (isDev) initEmulators();

const getAllDocs = async (colName) => await getDocs(collection(db, colName));

const getSingleDoc = async (col, docName) => {
  try {
    const docRef = doc(db, col, docName);
    const docSnap = await getDoc(docRef);

    return docSnap;
  } catch (err) {
    console.error(err);
  }
};

const getDocRef = (col) => {
  return doc(collection(db, col));
};

const getStoredFileUrl = async (path) => {
  try {
    // Create a reference with an initial file path and name
    const pathReference = ref(storage, path);
    return await getDownloadURL(pathReference);
  } catch (err) {
    console.error(err);
  }
};

const getSingleDocSnapshot = (col, docName, onSnapshotDo) => {
  // console.log("Getting snapshot col, docName", col, docName);
  return new Promise((resolve) => {
    const callMeToUnsubscribe = onSnapshot(
      doc(db, col, docName),
      async (snapshot) => {
        await onSnapshotDo(snapshot);
        resolve(callMeToUnsubscribe);
      }
    );
  });
};

const callFunction = (funcName) => httpsCallable(functions, funcName);
// export utils/refs
export {
  firebaseConfig,
  db,
  auth,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  storage,
  functions,
  callFunction,
  getAllDocs,
  getSingleDoc,
  getStoredFileUrl,
  getSingleDocSnapshot,
  signInWithEmailAndPassword,
  getDocRef,
  setDoc,
  updateDoc,
  query,
  where,
};
