<template lang="pug">
.price-block
    .price
        s.p-relative.mr-2
            span.regular.strikediag.mr-2
                small $
                | {{regularPrice}}
        span.sale.font-weight-bold
            small $
            | {{salePrice}}
        span.period.ml-2 / month
</template>

<script>
export default {
  name: "PriceBlock",
  props: {
    dark: { type: Boolean, default: false },
  },
  data() {
    return { regularPrice: "19", salePrice: "5" };
  },
};
</script>

<style lang="scss" scoped>
.price {
  font-size: 2rem;

  small {
    font-size: 70%;
  }

  .sale,
  sup {
    font-size: 2.75rem;
  }

  .strikediag {
    display: inline-block;
    position: relative;
  }
  .strikediag::before {
    content: "";
    position: absolute;
    left: -0.1em;
    right: -0.1em;
    top: 0.38em;
    bottom: 0.38em;
    background: linear-gradient(
      to left top,
      transparent 45.5%,
      maroon 47.5%,
      red 52.5%,
      transparent 54.5%
    );
    pointer-events: none;
  }

  .period {
    opacity: 0.7;
  }
}
</style>
