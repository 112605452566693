<template lang="pug">
    v-footer.footer(color="grey lighten-4")
        v-card.pa-5(flat color="transparent").mx-auto
            p.text-center.mb-0.d-inline-block For questions, feedback, and help:  <a href="mailto:support@SeriCity.com">support@SeriCity.com</a> or join <a href="tg://english_teachers_tv">@english_teachers_tv</a> on Telegram
</template>

<script>
import LessonsMenu from "@/components/Nav/LessonsMenu.vue";
import Usermenu from "@/components/Nav/UserMenu.vue";
import ToolsMenu from "@/components/Nav/ToolsMenu.vue";
// import GrammarMenu from "@/components/Nav/GrammarMenu.vue";
import GamesMenu from "@/components/Nav/GamesMenu.vue";

export default {
  name: "Footer",
  components: { Usermenu, ToolsMenu, LessonsMenu, GamesMenu },
};
</script>
