<template lang="pug">
  #main(:class="{debug: isDev}")
    v-app
      Navbar.d-print-none
      v-main
        Toast
        audio#audioElem
        .wrapper#top
          .d-none.d-print-block
            v-img(src="@/assets/logo_white_bg.png" contain height="80px" width="200px").mx-auto
            h1.mb-6 SeriCity.com lessons are designed to be used online. 
            h2 If you would like to be able to print lesson material, please contact us to discuss our enterprise options.
          
          .d-print-none
            TopBanner(v-if="!userData || !userData.subscriptionIsActive")
            SubscriptionNeeded
            router-view

            a.back-to-top.text-decoration-none(href="#top")
              v-btn(text)
                v-icon.mr-2 mdi-arrow-up
                span Top

      Footer
</template>

<script>
import { get, sync, call } from "vuex-pathify";
import Navbar from "@/components/Nav/Navbar.vue";
import SubscriptionNeeded from "@/components/Dialogs/SubscriptionNeeded";
import Toast from "@/components/Toast.vue";
import Footer from "@/components/Footer.vue";
import TopBanner from "@/components/Nav/TopBanner.vue";
export default {
  name: "App",
  components: { TopBanner, Navbar, Toast, SubscriptionNeeded, Footer },
  data: () => ({}),
  watch: {
    $route() {
      this.initToast({
        message: this.$route.query.toastMessage,
        timeout: 9000,
        color: "accent",
      });
    },
  },
  created() {},
  mounted() {
    this.initAudio();
    // if (!this.isDev) this.initCopyProtection();
    this.initCopyProtection();
  },
  computed: {
    ...get({
      isTeacher: "Lessons/isTeacher",
      copyProtection: "copyProtection",
      userData: "User/userData",
    }),
    ...sync({ showToast: "Nav/toast@show" }),
    isDev() {
      return window && !!window.webpackHotUpdate;
    },
  },
  methods: {
    ...call({ initAudio: "Audio/initAudio", initToast: "Nav/initToast" }),
    initCopyProtection() {
      if (!this.isDev) {
        document.addEventListener("copy", function (e) {
          const rootVueState = document.getElementsByTagName("a")[0].__vue__
            .$store.state; //because here we're at the document level, there's no Vue 'this'
          if (rootVueState.copyProtection) {
            e.clipboardData.setData(
              "text/plain",
              "If you would like to copy sericity.com lesson material, please contact us about our enterprise options."
            );
            e.preventDefault();
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/colors.scss";

$title-font-family: "Roboto";
$body-font-family: "Arimo";

html,
body {
  font-family: $body-font-family;
}

#main:not(.debug) {
  div,
  p,
  span,
  ol,
  ul,
  li {
    -webkit-user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
}

html {
  scroll-behavior: smooth;
}

.p-absolute {
  position: absolute;
}
.p-relative {
  position: relative;
}
.wrapper {
  max-width: 1200px;
  margin: auto;
}

html,
body,
.v-main,
.v-main__wrap {
  background-color: #e5eaf0;
}
.v-main.sericity-dark,
.v-main.sericity-dark .v-main__wrap {
  background-color: #2f2e41;
}

.v-app-bar {
  z-index: 99 !important;
}

.v-application {
  h1 {
    font-size: 2.25rem;
    line-height: 43.2px;
    font-weight: 700;
    color: $textDark;
  }
  h2 {
    font-size: 1.5rem;
    line-height: 28.8px;
    font-weight: 700;
    color: $textDark;
  }

  h4 {
    font-size: 1.25rem;
  }
  div,
  p,
  li {
    font-size: 1.15rem;
    line-height: 1.75;
    font-weight: 400;
    color: $textBody;
  }

  .oblique {
    font-family: "Lora" !important;
    font-style: oblique;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  .display-1,
  .display-2,
  .display-3 {
    font-family: $title-font-family, sans-serif !important;

    &.oblique,
    span.oblique {
      font-family: "Lora" !important;
      font-style: oblique;
    }
  }
  div,
  p,
  li,
  span {
    font-family: $body-font-family, sans-serif !important;
  }
}

.contain-width {
  max-width: 600px;
  margin: auto;
}

pre.debug {
  display: none;
}
.debug pre.debug {
  display: inherit;
}

h2 {
  font-size: 2.4rem;
  margin-bottom: 0.7rem;
}

p {
  font-size: 1.2rem;
}

p.small,
span.small,
li.small {
  font-size: 0.95rem;
}

.back-to-top {
  position: fixed;
  display: block;
  bottom: 2rem;
  right: 2rem;
  width: 75px;
  opacity: 50%;
  height: 50px;
  z-index: 99;
}

.firebase-emulator-warning {
  display: none;
}
</style>
