import {
  auth,
  createUserWithEmailAndPassword,
  callFunction,
  getSingleDocSnapshot,
  signInWithEmailAndPassword,
} from "@/firebase.js";
import { make } from "vuex-pathify";
// import router from "@/router/index.js";
const state = { userData: null, userDataSubscription: null };
const getters = {
  ...make.getters(state),
};

const mutations = make.mutations(state);

const actions = {
  createUser: async ({ dispatch }, { email, password }) => {
    if (!email || !password)
      throw new Error(
        "You must provide both an email and a password in order to create a new user"
      );

    return createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const { email, uid } = userCredential.user;
        const createUserInFirestore = callFunction("createUserInFirestore");
        await createUserInFirestore({ email, uid });
        await dispatch("bindUserData", { id: uid });
        return uid;
      })
      .catch((error) => {
        throw new Error(error.message);
      });
  },

  signIn: async ({ dispatch }, { email, password }) => {
    try {
      if (!email || !password) return false;
      const signInRes = await signInWithEmailAndPassword(auth, email, password);
      if (signInRes && signInRes.user && signInRes.user.uid) {
        await dispatch("bindUserData", { id: signInRes.user.uid });

        return signInRes;
      } else {
        throw new Error({
          message: "Unknown error while logging you in",
        });
      }
    } catch (err) {
      console.error(err);
      return err ? { error: err.message } : { error: "Unknown Error" };
    }
  },

  userAlreadyExists: async (context, { email }) => {
    if (email) {
      const checkIfUserAlreadyExists = callFunction("checkIfUserAlreadyExists");

      const res = await checkIfUserAlreadyExists({ email });

      return res && res.data;
    } else {
      throw new Error("You did not provide a valid email address");
    }
  },

  logout: async ({ getters, commit }) => {
    if (getters.userDataSubscription) getters.userDataSubscription();

    if (auth && auth.currentUser) {
      return await auth.currentUser.getIdToken(true).then(async () => {
        await auth.signOut();
        commit("SET_USER_DATA", null);
      });
    }
  },

  bindUserData: async ({ commit }, { id }) => {
    try {
      const firebaseUserId = id
        ? id
        : auth.currentUser
        ? auth.currentUser.uid
        : null;

      if (!firebaseUserId) {
        commit("SET_USER_DATA", null);
        commit("SET_USER_DATA_SUBSCRIPTION", null);
      } else {
        // console.log("binding user data with firebase id", firebaseUserId);

        const onSnapshotDo = async (snapshot) => {
          // console.log("processing snapshot");
          const data = snapshot.data();

          return await auth.currentUser.getIdToken(true).then(async () => {
            const tokens = await auth.currentUser.getIdTokenResult();
            const claims = tokens && tokens.claims ? tokens.claims : {};
            // console.log("token claims", tokens.claims);

            const { subscriptionIsActive, subscriptionWasCanceled } = claims;
            commit("SET_USER_DATA", {
              ...data,
              subscriptionIsActive,
              subscriptionWasCanceled,
            });
            // console.log("Done getting snapshot and setting user data");

            // console.log("done onSnapshot");
          });
        };

        const userDataSubscription = await getSingleDocSnapshot(
          "users",
          firebaseUserId,
          onSnapshotDo
        );
        commit("SET_USER_DATA_SUBSCRIPTION", userDataSubscription);
      }
      // console.log("done binding");
    } catch (err) {
      console.error(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
