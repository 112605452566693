<template lang="pug">
    .tools-menu
        v-menu(offset-y bottom open-on-hover)
          template(v-slot:activator="{ on, attrs }")
            v-btn(text v-on="on").btn.font-weight-bold Games
          v-list(max-width="400px")
            v-list-item-group(v-model="selectedItem")
              v-list-item(v-for="itm in items", :key="itm.to", :to="itm.to")
                .d-flex
                    v-icon.mr-2 {{itm.icon}}
                    v-list-item-title {{itm.text}}

</template>

<script>
import { get, sync } from "vuex-pathify";
export default {
  name: "GamesMenu",
  computed: {
    ...get({ userData: "User/userData" }),
    ...sync({ subscriptionNeeded: "Nav/dialogs@subscriptionNeeded" }),
  },
  data() {
    return {
      selectedItem: 1,
      items: [
        {
          text: "Guess The Word",
          to: "/games/hangman/shows",
          icon: "mdi-head-question",
        },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
