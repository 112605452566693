<template lang="pug">
    v-app-bar(app absolute flat dark align="top", color="primary" background-color="red" :height="height"  min-width="200px" max-width="1200px")
        v-row(dense justify="space-between" align="center").pt-3
            v-col(xs="8" sm="4").text-md-left
                router-link(to="/")
                    v-img(src="@/assets/logo_white_bg.png" contain height="70px" :width="$vuetify.breakpoint.mdAndUp ? '200px' : '175px'", :class="{'mt-3': expanded}").mx-auto.mx-md-3

            v-col(cols="4" sm="2").text-right
                v-btn(icon)
                    v-icon(@click="expanded = !expanded") {{expanded ? 'mdi-close' : 'mdi-menu'}}
            
            v-col(cols="12")
                .expanded.d-flex.flex-wrap(v-if="expanded")
                    v-btn(to="/student-guide" text).btn.font-weight-bold Student Guide
                    LessonsMenu
                    ToolsMenu
                    //- GrammarMenu
                    Usermenu

</template>

<script>
import { get } from "vuex-pathify";
import Usermenu from "@/components/Nav/UserMenu.vue";
import ToolsMenu from "@/components/Nav/ToolsMenu.vue";
import LessonsMenu from "@/components/Nav/LessonsMenu.vue";
// import GrammarMenu from "@/components/Nav/GrammarMenu.vue";

export default {
  name: "NavbarDesktop",
  components: { Usermenu, LessonsMenu, ToolsMenu },
  computed: {
    ...get({ isDev: "isDev", isTeacher: "Lessons/isTeacher" }),
    height() {
      return this.expanded ? "180px" : "80px";
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
};
</script>
