// import router from '@/router'
import sortBy from "lodash.sortby";
import {
  getAllDocs,
  getSingleDoc,
  getStoredFileUrl,
  callFunction,
  auth,
} from "@/firebase.js";
import { make } from "vuex-pathify";

const state = {
  lesson: null,
  isTeacher: false,
  lessons: [],
  lessonsFilter: "Rick and Morty",
  freeEpisodeNums: [1, 2, 5],
  placeholders: [
    {
      show: "Ratched",
      season: 1,
      startEp: 1,
      endEp: 8,
      active: false,
      adultsOnly: true,
      shortForm: "Rat",
      colors: { foreground: "white", background: "#03B39B" },
    },
    {
      show: "The Good Place",
      season: 1,
      startEp: 1,
      endEp: 13,
      active: false,
      shortForm: "GP",
      colors: { foreground: "#F2CA59", background: "#0275BD" },
    },
    {
      show: "Rick and Morty",
      season: 3,
      startEp: 3,
      endEp: 3,
      active: false,
      adultsOnly: true,
      shortForm: "R&M",
      colors: { foreground: "#FBF265", background: "#282763" },
    },
    {
      show: "Brooklyn Nine Nine",
      season: 1,
      startEp: 1,
      endEp: 6,
      active: false,
      shortForm: "B99",
      colors: { foreground: "primary", background: "#FDF04C" },
    },
    {
      show: "Avatar",
      season: 1,
      startEp: 1,
      endEp: 5,
      active: false,
      shortForm: "Av",
      colors: { foreground: "#FFFBD6", background: "#081D81" },
      isKidFriendly: true,
    },
  ],
};
const getters = {
  ...make.getters(state),
  filteredLessons: (state) => {
    if (!state.lessonsFilter) return state.lessons;
    else {
      return sortBy(
        state.lessons.filter(
          (lesson) =>
            lesson.show.toLowerCase().split(" ").join("_") ===
            state.lessonsFilter.toLowerCase().split(" ").join("_")
        ),
        "season"
      );
    }
  },
  shows: (state) =>
    sortBy(
      state.placeholders.map((show) => {
        const {
          show: title,
          isKidFriendly,
          adultsOnly,
          shortForm,
          colors,
        } = show;
        return {
          title,
          isKidFriendly,
          adultsOnly,
          shortForm,
          colors,
        };
      }),
      "title"
    ),
  showColors: (state) => {
    const obj = {};
    state.placeholders.forEach((itm) => (obj[itm.show] = itm.colors));
    return obj;
  },
};

const mutations = make.mutations(state);

const actions = {
  clearLesson: async ({ commit }) => {
    commit("SET_LESSON", null);
  },
  updateLesson: async ({ commit }, { id }) => {
    const lessonRef = await getSingleDoc("lessons", id);
    commit("SET_LESSON", { id: lessonRef.id, ...(await lessonRef.data()) });
  },
  updateLessons: async ({ getters, commit, dispatch }) => {
    const userId =
      auth.currentUser && auth.currentUser.uid ? auth.currentUser.uid : null;

    await dispatch("User/bindUserData", { userId }, { root: true });

    const lessonsRef = await getAllDocs("lessons");
    const lessons = lessonsRef.docs.map((doc) => {
      return { id: doc.id, ...doc.data() };
    });

    const placeholderEps = getters.placeholders
      .map((itm) => {
        return new Array(itm.endEp)
          .fill()
          .map((d, i) => i + 1)
          .map((num) => {
            return {
              show: itm.show,
              active: itm.active,
              id:
                itm.show.toLowerCase().split(" ").join("") +
                "-" +
                itm.season +
                "-" +
                num,
              season: itm.season,
              episode: num,
              created: itm.created,
              isKidFriendly: itm.isKidFriendly,
            };
          })
          .slice(itm.startEp - 1);
      })
      .flat();

    const placeholderEpsMinusLessonsThatAreLive = placeholderEps.filter(
      (ep) =>
        !lessons.find(
          (lesson) =>
            lesson.show === ep.show &&
            lesson.season === ep.season &&
            lesson.episode === ep.episode
        )
    );

    const intersection = [...lessons, ...placeholderEpsMinusLessonsThatAreLive];
    const sorted = sortBy(intersection, "episode");
    commit("SET_LESSONS", sorted);
  },
  getAudioFromStorage: async ({ getters }, { type, filename }) => {
    const lessonId = getters.lesson.id;

    return await getStoredFileUrl(`tts/${lessonId}/${type}/${filename}`);
  },
  createStudentVersion: async ({ getters }) => {
    // console.log("About to get student version...");
    try {
      const createStudentVersionOfLesson = await callFunction(
        "createStudentVersionOfLesson"
      );

      const res = await createStudentVersionOfLesson({
        userId: "guest",
        lessonId: getters.lesson.id,
      });
      if (res && res.data) {
        return res.data;
      } else {
        return null;
      }
    } catch (err) {
      console.error("error getting student version link", err);
    }
  },
  getLessonIdFromExpiringLesson: async (context, { id }) => {
    // window.debug("about to get lesson id from expiring lesson", id);
    try {
      const getLessonIdFromExpiringLesson = callFunction(
        "getLessonIdFromExpiringLesson"
      );

      const res = await getLessonIdFromExpiringLesson({
        id,
      });

      if (res && res.data) {
        // window.debug("got data", res.data);
        return res.data;
      } else {
        throw new Error("Could not find this lesson Id");
      }
    } catch (err) {
      console.error(err);
      throw new Error(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
