<template lang="pug">
    .subscription-needed
      SubscriptionBottomOverlay

      v-dialog(v-model="subscriptionNeeded" max-width="850px" overlay-color="primary", overlay-opacity="0.95", :transition="false")
        .bordered(:style="{border: '7px solid', 'border-color': $vuetify.theme.themes.light.accent}")
          .text-center.primary(v-if="$vuetify.breakpoint.mdAndUp")
            v-img(eager src="@/assets/logo_white_bg.png" lazy-src="https://via.placeholder.com/240x120/2F2E41/FFFFFF?text=SeriCity" contain max-width="240px").py-4.mx-auto
          v-card.pa-2.pa-md-5(tile flat)
            v-row(align="center" dense)
              v-col(cols="12").text-center
                .mb-3.mb-md-5.font-weight-bold(:class="{'text-h5': $vuetify.breakpoint.smAndDown, 'text-h4': $vuetify.breakpoint.mdAndUp}")
                  | Netflix Lesson Plans for English Teachers
              v-col(cols="12" md="6")
                v-row.benefit(dense v-for="benefit, idx in benefits", :key="benefit")
                  v-col(cols="2" md="1").text-right.text-md-left
                    v-icon(color="accent").mr-2 mdi-check-circle
                  v-col(cols="10" md="11")
                    span.benefit(:class="{small: $vuetify.breakpoint.smAndDown}", v-html="benefit")
              v-col(cols="12" md="6")
                .d-flex.justify-space-between
                  v-divider(v-if="$vuetify.breakpoint.mdAndUp", vertical).mr-6
                  template(v-if="!userData || !userData.id")
                    RegistrationMini.flex-grow-1( @click:dismiss="subscriptionNeeded=false")
                  template(v-else-if="userData && userData.id")
                    BillingMini.flex-grow-1(showCancelButton @click:dismiss="subscriptionNeeded=false")
                  

</template>

<script>
import { get, sync, call } from "vuex-pathify";
import SubscriptionBottomOverlay from "@/components/Billing/SubscriptionBottomOverlay.vue";
import RegistrationMini from "@/components/User/RegistrationMini.vue";
import BillingMini from "@/components/Billing/BillingMini.vue";
import PriceBlock from "@/components/Billing/PriceBlock.vue";
export default {
  name: "SubscriptionNeeded",
  components: {
    SubscriptionBottomOverlay,
    RegistrationMini,
    BillingMini,
    PriceBlock,
  },
  data() {
    return {};
  },

  computed: {
    ...get({
      userData: "User/userData",
      benefits: "Billing/benefits",
    }),
    ...sync({ subscriptionNeeded: "Nav/dialogs@subscriptionNeeded" }),
  },
  methods: {
    ...call({ goToCheckoutIfNeeded: "Billing/goToCheckoutIfNeeded" }),
  },
};
</script>

<style lang="scss" scoped>
.benefit.small {
  display: inline-block;
}
</style>
