import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index";
import { Payload } from "vuex-pathify";
import { auth } from "@/firebase.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    // component: Home,
    redirect: "/lessons",
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/init-lessons/:id?",
    name: "InitLessons",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/InitLessons.vue"),
  },
  {
    path: "/create-lesson",
    name: "CreateLesson",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "admin" */ "../views/CreateLesson.vue"),
  },
  {
    path: "/lessons",
    name: "Lessons",
    component: () =>
      import(/* webpackChunkName: "lessons" */ "../views/Lessons.vue"),
  },
  {
    path: "/lessons/create",
    name: "CreateLesson",
    component: () =>
      import(/* webpackChunkName: "LessonCreator" */ "../views/Creator.vue"),
  },
  {
    path: "/lessons/:id",
    name: "LessonsWithFilter",
    component: () =>
      import(/* webpackChunkName: "lessons" */ "../views/Lessons.vue"),
  },
  {
    path: "/lesson/:id",
    name: "Lesson",
    component: () =>
      import(/* webpackChunkName: "lessons" */ "../views/Lesson.vue"),
    meta: { requiresSubscription: true },
  },
  {
    path: "/students/lesson/:id",
    name: "StudentLesson",
    component: () =>
      import(/* webpackChunkName: "lessons" */ "../views/StudentLesson.vue"),
  },
  {
    path: "/student-guide",
    name: "StudentGuide",
    component: () =>
      import(/* webpackChunkName: "static" */ "../views/StudentGuide.vue"),
  },
  {
    path: "/tools/writing-correction",
    name: "WritingCorrectionTool",
    component: () =>
      import(/* webpackChunkName: "tools" */ "../views/Corrections.vue"),
  },
  {
    path: "/tools/hangman-maker",
    name: "HangmanMaker",
    component: () =>
      import(/* webpackChunkName: "tools" */ "../views/Tools/HangmanMaker.vue"),
  },
  {
    path: "/tools/writing-correction/:id",
    name: "WritingCorrectionToolEdit",
    component: () =>
      import(/* webpackChunkName: "tools" */ "../views/Corrections.vue"),
  },
  {
    path: "/tools/corrections/:id",
    name: "WritingCorrectionReview",
    component: () =>
      import(/* webpackChunkName: "tools" */ "../views/CorrectionsReview.vue"),
  },
  {
    path: "/tools/image-timer",
    name: "ImageTimer",
    component: () =>
      import(
        /* webpackChunkName: "toolsImageTimer" */ "../views/Tools/ImageTimer.vue"
      ),
  },
  {
    path: "/grammar/:lesson/:section",
    name: "GrammarSection",
    component: () =>
      import(/* webpackChunkName: "grammar" */ "../views/Grammar.vue"),
  },
  {
    path: "/games/hangman/shows",
    name: "Hangman",
    component: () =>
      import(/* webpackChunkName: "hangman" */ "../views/Games/Hangman.vue"),
  },
  {
    path: "/games/hangman/shows/:show",
    name: "HangmanWithShowId",
    component: () =>
      import(/* webpackChunkName: "hangman" */ "../views/Games/Hangman.vue"),
  },
  {
    path: "/games/hangman/:worksheetId",
    name: "HangmanWithWorksheetId",
    component: () =>
      import(/* webpackChunkName: "hangman" */ "../views/Games/Hangman.vue"),
  },
  {
    path: "/contact/pro-account-offer",
    name: "ProAccountOffer",
    component: () =>
      import(
        /* webpackChunkName: "ProAccountOffer" */ "../views/ProAccountOffer.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.getElementById("app").scrollIntoView();
  },
});

const loginStatus = async () => {
  try {
    const firebaseId = auth.currentUser ? auth.currentUser.uid : null;

    const userData =
      store && store.getters && store.getters["User/userData"]
        ? store.getters["User/userData"]
        : null;

    const isLoggedIn = userData ? !!userData.id : false;
    const subscriptionIsActive = userData
      ? userData.subscriptionIsActive
      : false;

    return { firebaseId, isLoggedIn: !!isLoggedIn, subscriptionIsActive };
  } catch (err) {
    console.error("loginStatus err: ", err);
    return false;
  }
};

const doLoginUser = async (id) =>
  await store.dispatch("User/bindUserData", { id });

router.beforeEach(async (to, from, next) => {
  console.log("\n\nprocessing router beforeEach", to);
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);

  const freeEpisodeNums =
    store && store.getters ? store.getters["Lessons/freeEpisodeNums"] : [];
  const requiresSubscription =
    to.matched.some((x) => x.meta.requiresSubscription) &&
    !freeEpisodeNums.find((freeEpPartialId) =>
      to.path.toLowerCase().includes("_e0" + freeEpPartialId)
    );

  // Stage 1
  const { isLoggedIn, firebaseId, subscriptionIsActive } = await loginStatus();

  if (firebaseId && !isLoggedIn) {
    await doLoginUser(firebaseId);
  }

  const newLoginStatus = await loginStatus();
  const nowIsLoggedIn = newLoginStatus.isLoggedIn;
  const nowSubscriptionIsActive = newLoginStatus.subscriptionIsActive;

  if (requiresAuth && !nowIsLoggedIn) {
    next("/login");
  } else if (
    requiresSubscription &&
    !(subscriptionIsActive || nowSubscriptionIsActive)
  ) {
    window.debug("requires subscription and has no active subscription");

    to.params.requiresSubscription = true;

    store.commit(
      "Nav/SET_DIALOGS",
      new Payload("Nav/SET_DIALOGS", "subscriptionNeeded", true)
    );
    next();
  } else {
    window.debug("Does not require subscription");
    next();
  }
});

export default router;
