// import router from "@/router";
// import Firebase from "firebase/app";
// import "firebase/auth";
// import { firebaseConfig } from "@/firebase.js";
import { make } from "vuex-pathify";
// import { scrollToTarget } from "@/helpers.js";

const state = {
  showPreview: false,
  meta: {},
  questions: {},
  keyExpressions: [
    // {
    //   id: "sdfjskdlfj!_dsfdf2-4",
    //   expression: "A dime a dozen",
    //   meaning: "There are many of them and they are easy to get",
    //   example:
    //     "Printers used to be really expensive but now they're a dime a dozen",
    //   types: ["idiom"],
    // },
    {
      id: "sdfjskdlfj!_dsfdf2-4",
      expression: "",
      meaning: "",
      example: "",
      types: [],
    },
  ],
};
const getters = {
  ...make.getters(state),
};

const mutations = make.mutations(state);

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
