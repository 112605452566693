<template lang="pug">
    .top-banner#topBanner(:style="{width: '100%'}")
        .d-flex.flex-column.justify-center.align-center
            a(href="#" @click.prevent="subscriptionNeeded = true").text-center.text-decoration-none.white--text
                .message(:style="{'margin-bottom': '0.5rem', 'font-weight': 900}") Summer 75% OFF SALE! Get an unlimited Pro Subscription for only $5/month
                //- .time-remaining(:style="{opacity:0.9}") {{timeRemaining}}
</template>

<script>
import { sync } from "vuex-pathify";
export default {
  name: "TopBanner",
  data() {
    return { timer: null, timeRemaining: "Time is running out!" };
  },
  beforeDestroy() {
    if (this.timer) clearInterval(this.timer);
  },
  created() {
    this.startCountdown(new Date(2022, 0, 4));
  },
  mounted() {},
  computed: {
    ...sync({ subscriptionNeeded: "Nav/dialogs@subscriptionNeeded" }),
  },
  methods: {
    startCountdown(end) {
      const _second = 1000;
      const _minute = _second * 60;
      const _hour = _minute * 60;
      const _day = _hour * 24;

      const showRemaining = () => {
        var now = new Date();
        var distance = end - now;
        if (distance < 0) {
          clearInterval(this.timer);
          return;
        }

        let remainingDays = Math.floor(distance / _day);
        let remainingHours = Math.floor((distance % _day) / _hour);
        let remainingMinutes = Math.floor((distance % _hour) / _minute);
        let remainingSeconds = Math.floor((distance % _minute) / _second);

        remainingDays += " days";
        remainingHours += " hrs";
        remainingMinutes += " mins";
        remainingSeconds += " secs";

        const sep = ", ";
        this.timeRemaining =
          remainingDays +
          sep +
          remainingHours +
          sep +
          remainingMinutes +
          sep +
          remainingSeconds;
      };

      this.timer = setInterval(showRemaining, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.top-banner {
  padding: 1rem;
  color: white;
  font-family: sans-serif;
  background: #ea1e63;
  max-width: 1200px;
  margin: auto;
}
</style>
