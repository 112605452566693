import Vue from "vue";
import Vuex from "vuex";
import pathify from "vuex-pathify";
import { make } from "vuex-pathify";
Vue.use(Vuex);

const state = {
  hostname: "https://sericity.com",
  copyProtection: true,
};

// import User from "./modules/User";
import Lessons from "./modules/Lessons";
import Audio from "./modules/Audio";
import Nav from "./modules/Nav";
import User from "./modules/User";
import Billing from "./modules/Billing";
import Tools from "./modules/Tools";
import LessonCreator from "./modules/LessonCreator";

export default new Vuex.Store({
  namespaced: true,
  plugins: [pathify.plugin], // activate plugin
  state,
  getters: {
    isDev: () => window && !!window.webpackHotUpdate,
    ...make.getters(state),
    hostname: (state, getters) =>
      getters.isDev ? "http://localhost:8080" : state.hostname,
    isMobile: () => {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
    functionsHostname: (state, getters) => {
      return getters.isDev
        ? "http://localhost:5001/sericity-v1/us-central1"
        : "https://us-central1-sericity-v1.cloudfunctions.net";
    },
  },
  mutations: { ...make.mutations(state) },
  actions: {},
  modules: { Lessons, Audio, Nav, User, Billing, Tools, LessonCreator },
});
