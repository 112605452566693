<template lang="pug">
    .subscription-bottom-overlay.overlay(v-if="showOverlay", :class="{fixed, inline, 'overlay-sm': $vuetify.breakpoint.smAndUp}")
        v-card.pa-3.pa-md-12.pt-0(tile height="100%" width="100%" color="primary" dark)
          .wrapper
            v-row
              v-col(cols="12" md="6")
                v-img(v-if="$vuetify.breakpoint.mdAndUp", src="@/assets/logo_white_bg.png" contain max-width="280px", eager).mb-4.mx-auto.mx-md-0
                v-row.benefits(dense v-for="benefit, idx in benefits", :key="benefit")
                  v-col(cols="1")
                    v-icon(color="accent").mr-2 mdi-check-circle
                  v-col(cols="11")
                    span.small(v-html="benefit").white--text

              v-col(cols="12" md="6")
                .text-center.text-md-left
                  PriceBlock
                  
                  .title.d-inline-block.fancy-underline(ref="fancyunderline", v-if="false") To access {{lesson.show}} S{{lesson.season}}-E{{lesson.episode}},
                    span(v-intersect="handleIntersectBottomOverlay") you need a
                      span.pro-subscription.secondary--text.ml-2 Pro Subscription
                  v-btn(x-large :color="loading ? 'success' : 'accent'" @click="handleSubscribe").mt-6 {{buttonText}}
</template>

<script>
import { annotate } from "rough-notation";
import { get, sync, call } from "vuex-pathify";
import PriceBlock from "@/components/Billing/PriceBlock.vue";
import { waitForElemToExist } from "@/helpers.js";
export default {
  name: "SubscriptionBottomOverlay",
  components: { PriceBlock },
  props: {
    fixed: { type: Boolean, default: true },
    inline: { type: Boolean, default: false },
  },

  data() {
    return {
      loading: false,
    };
  },
  created() {
    this.loading = false;
  },
  mounted() {},
  computed: {
    ...get({
      isMobile: "isMobile",
      lesson: "Lessons/lesson",
      userData: "User/userData",
      benefits: "Billing/benefits",
    }),
    ...sync({ subscriptionNeeded: "Nav/dialogs@subscriptionNeeded" }),
    buttonText() {
      if (this.loading) return "Redirecting...";

      return this.userData && this.userData.id
        ? "Subscribe Now"
        : "Sign up or Login";
    },
    showOverlay() {
      const userIsSubscribed = !!(
        this.userData && this.userData.subscriptionIsActive
      );

      const isFreeEpisode = this.lesson
        ? this.lesson.episode === 1 || this.lesson.episode === 2
        : false;

      if (this.inline && !isFreeEpisode && !userIsSubscribed) return true;

      const subscriptionDialogIsShowing = this.subscriptionNeeded;
      const routeRequiresSubscription = !!(
        this.$route.params && this.$route.params.requiresSubscription
      );

      const res = !!(
        !subscriptionDialogIsShowing &&
        routeRequiresSubscription &&
        !userIsSubscribed
      );

      return res;
    },
  },
  methods: {
    ...call({ goToCheckoutIfNeeded: "Billing/goToCheckoutIfNeeded" }),
    handleSubscribe() {
      if (!this.userData || !this.userData.email) {
        // show login
        this.subscriptionNeeded = true;
      } else {
        this.loading = true;
        this.goToCheckoutIfNeeded();
      }
    },
    handleIntersectBottomOverlay(entries, observer) {
      window.debug("intersect!", entries, observer);
      setTimeout(() => {
        this.animate();
      }, 1000);
    },
    async animate() {
      await waitForElemToExist(".fancy-underline");
      const e = this.$refs.fancyunderline;
      window.debug("fancyunderline e", e);
      const color = this.$vuetify.theme.themes.light.primary;
      console.log({ color });
      const annotation = annotate(e, {
        type: this.isMobile ? "highlight" : "box",
        color: this.isMobile ? "#D80002" : "#03B49B",
        strokeWidth: 2,
      });

      window.debug({ annotation });
      annotation.show();
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  left: 0;
  border-top: 10px solid rgba(47, 46, 66, 0.5);
  bottom: 0;
  z-index: 100;
}
.overlay-sm {
  height: unset;
  min-height: unset;
}

.fixed {
  position: fixed;
  width: 100vw;
}
.inline {
  width: unset;
  position: relative;
}

.benefits s {
  color: red;
}
</style>
