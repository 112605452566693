// import router from '@/router'
// import Firebase from "firebase/app";
// import "firebase/auth";
import {
  getDocRef,
  setDoc,
  updateDoc,
  getSingleDoc,
  auth,
} from "@/firebase.js";
import { make } from "vuex-pathify";

const state = { correction: null, worksheet: null };
const getters = {
  ...make.getters(state),
};

const mutations = make.mutations(state);

const actions = {
  createCorrectionForReview: async (context, { original, edited }) => {
    try {
      const doc = getDocRef("corrections");

      const userId = auth && auth.currentUser ? auth.currentUser.uid : null;

      const obj = {
        original,
        edited,
        created: new Date(),
        id: doc.id,
        userId,
      };

      window.debug("set doc ", setDoc);
      await setDoc(doc, obj);

      return doc.id;
    } catch (err) {
      console.error(err);
    }
  },
  updateCorrection: async ({ commit }, { id }) => {
    const ref = await getSingleDoc("corrections", id);
    const data = await ref.data();
    window.debug("for id", id, "got correction data", data);
    commit("SET_CORRECTION", { ...data, id: ref.id });
  },
  updateWorksheet: async ({ commit }, { id }) => {
    window.debug("updating worksheet for id", id);
    const ref = await getSingleDoc("worksheets", id);
    const data = await ref.data();
    window.debug("for id", id, "got worksheet data", data);
    commit("SET_WORKSHEET", { ...data, id: ref.id });
  },
  createWorksheet: async (context, { title, expressions, worksheetId }) => {
    try {
      const alreadyExists = worksheetId
        ? await getSingleDoc("worksheets", worksheetId)
        : null;

      window.debug("alreadyExists", alreadyExists);
      const userId = auth && auth.currentUser ? auth.currentUser.uid : null;

      if (alreadyExists) {
        const obj = {
          title,
          expressions,
          created: new Date(),
          id: alreadyExists.id,
          userId,
        };

        window.debug("update doc ", obj);
        await updateDoc(alreadyExists.ref, obj);

        return alreadyExists.id;
      } else {
        const doc = getDocRef("worksheets");

        const obj = {
          title,
          expressions,
          created: new Date(),
          id: doc.id,
          userId,
        };

        window.debug("set doc ", setDoc);
        await setDoc(doc, obj);

        return doc.id;
      }
    } catch (err) {
      console.error(err);
    }
  },
  updateExistingWorksheet: async (context, { title, expressions }) => {
    try {
      const doc = getDocRef("worksheets");

      const userId = auth && auth.currentUser ? auth.currentUser.uid : null;

      const obj = {
        title,
        expressions,
        created: new Date(),
        id: doc.id,
        userId,
      };

      window.debug("set doc ", setDoc);
      await updateDoc(doc, obj);

      return doc.id;
    } catch (err) {
      console.error(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
