import router from '@/router';
// import Firebase from "firebase/app";
// import "firebase/auth";
import { firebaseConfig } from '@/firebase.js';
import { make } from 'vuex-pathify';
import { scrollToTarget } from '@/helpers.js';

const state = {
  dialogs: {
    subscriptionNeeded: false,
    shareCorrections: false,
    showIncorrectWritingInput: false,
    showCancelSubscription: false,
  },
  toast: {
    show: false,
    message: null,
    timeout: 5000,
  },
  topBanner: {
    show: true,
    message: null,
  },
  grammarActiveComponent: null,
  sections: {
    warmUp: 'Warm-Up',
    summary: 'Plot Summary',
    keyExpressions: 'Key Expressions',
    checkUnderstanding: 'Check Understanding',
    discussion: 'Discussion',
    shadowing: ' Shadowing',
    rolePlay: 'Role Play',
    cloze: 'Exercise',
    matching: 'Matching Game',
    dictation: 'Listening Game',
    imagePrompts: 'Image Prompts',
    articlesPractice: 'Articles (a/an/the)',
    hangman: 'Guess The Word',
    tensePractice: 'Tense Practice',
  },
};
const getters = {
  ...make.getters(state),
};

const mutations = make.mutations(state);

const actions = {
  navToSectionTitle: ({ getters }, { to, from }) => {
    try {
      if (from) history.pushState(null, 'Exercise', from);

      const entries = Object.entries(getters.sections);

      const target = entries.filter((entry) => entry.includes(to));
      console.log('scrolling to', to, 'target', target);

      if (target && target.length > 0) {
        const navTo = target[0][0];
        console.log({ navTo });

        const url = location.href; //Save down the URL without hash.
        history.replaceState(null, to, url);
        location.href = '#' + navTo; //Go to the target element.

        setTimeout(() => {
          const elem = document.getElementById(navTo);
          if (elem) scrollToTarget(elem);
        }, 300);
      } else {
        const elem = document.getElementById(to);
        if (elem) scrollToTarget(elem);
      }
    } catch (err) {
      console.error(err);
    }
  },
  grammarSectionNav: (context, { lesson, section }) => {
    router.push('/grammar/' + lesson + '/' + section);
    // history.replaceState(null, "Grammar", section);
    // commit("SET_GRAMMAR_ACTIVE_COMPONENT", { lesson, section });
  },
  getDynamicLink: async (context, { longLink }) => {
    try {
      const res = await fetch(
        'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=' +
          firebaseConfig.apiKey,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            longDynamicLink:
              'https://links.sericity.com/corrections?link=' +
              encodeURIComponent(longLink),
          }),
        }
      );

      const json = await res.json();
      if (json.error) throw new Error(json.error.message);

      return json.shortLink;
    } catch (err) {
      console.error(err);
    }
  },
  setIsDarkPage: (context, payload) => {
    if (payload) {
      document.querySelector('.v-main').classList.add('sericity-dark');
    } else {
      document.querySelector('.v-main').classList.remove('sericity-dark');
    }
  },
  initToast({ commit }, { message, timeout = 15000, color = 'accent' }) {
    const newToast = {
      show: true,
      message,
      timeout,
      color,
    };
    commit('SET_TOAST', newToast);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
