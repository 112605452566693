// import router from '@/router'
import { callFunction } from "@/firebase.js";
import { make } from "vuex-pathify";

const state = {
  benefits: [
    "Save hours of prep time with hundreds of hours of ready-made TV Show lesson content",
    "All materials made by professional native English instructors",
    "Summer Sale! Subscribe for <b>unlimited</b> access for <s><em>$19</em></s> <b>$5/month</b>",
  ],
};
const getters = {
  ...make.getters(state),
};

const mutations = make.mutations(state);

const actions = {
  cancelSubscription: async ({ dispatch, rootGetters }) => {
    window.debug("sending user to checkout...");
    const cancelSubscription = await callFunction("cancelSubscription");
    window.debug("when getting checkout link rootGetters", rootGetters);

    const userData = rootGetters["User/userData"];
    const { id: dbUserId, email, stripeCustomerId } = userData;

    const cancelSubscriptionRes = await cancelSubscription({
      dbUserId,
      email,
      stripeCustomerId,
    });

    window.debug({ cancelSubscriptionRes });

    await dispatch("User/bindUserData", { id: dbUserId }, { root: true });

    return cancelSubscriptionRes && cancelSubscriptionRes.data
      ? cancelSubscriptionRes.data
      : null;
  },
  getCheckoutLink: async ({ rootGetters }) => {
    window.debug("sending user to checkout...");
    const getCheckoutLink = await callFunction("getCheckoutLink");
    window.debug("when getting checkout link rootGetters", rootGetters);

    const userData = rootGetters["User/userData"];
    const { id: dbUserId, email, stripeCustomerId } = userData;

    const checkoutLink = await getCheckoutLink({
      dbUserId,
      email,
      stripeCustomerId,
    });

    return checkoutLink && checkoutLink.data ? checkoutLink.data : null;
  },
  goToCheckoutIfNeeded: async ({ rootGetters, dispatch }) => {
    const userData = rootGetters["User/userData"];
    window.debug(
      "when determining whether to check out, user data is",
      JSON.stringify(userData)
    );
    if (userData && !userData.subscriptionIsActive) {
      const checkoutLink = await dispatch("getCheckoutLink");
      if (checkoutLink && checkoutLink.includes("checkout.stripe"))
        location.replace(checkoutLink);
      else throw new Error("Could not redirect to checkout");
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
