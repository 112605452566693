import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

const vuetifyOpts = {
  rtl: false,
  customProperties: true,

  theme: {
    // dark: true,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#2F2E41",
        accent: "#03B39B",
        secondary: "#FFFDEE",
        success: "#6FCF97",
        info: "#2196F3",
        warning: "#FFB429",
        error: "#FF99A1",
        red: "#EB5757",
      },
    },
  },
};

Vue.use(Vuetify);

export default new Vuetify(vuetifyOpts);
