<template lang="pug">
    .registration-mini

        .text-center(v-if="!showLogin")
          p(:class="{small: $vuetify.breakpoint.smAndDown}") For each TV Show, you get a couple of lesson plans
            span.font-weight-bold.ml-1.d-inline-block.d-md-inline absolutely free!
          v-btn(color="accent" x-large block @click="$emit('click:dismiss')").font-weight-bold.mt-3
            | Start as guest
            v-icon.ml-2 mdi-arrow-right
          v-btn(v-if="!showLogin" text @click="showLogin=true" large block).mt-3
            | Start with account
        
        v-expand-transition
          .login-area(v-if="showLogin")
            .text-center.mb-3
              span Subscribe now!
            v-text-field(v-model="email", label="Your email", placeholder="your.name@company.com" outlined hide-details, @keypress.enter="handleSignIn").mb-3
            v-text-field(v-model="password", label="Password", placeholder="Min 8 characters + number" outlined type="password" hide-details, @keypress.enter="handleSignIn").mb-3
            v-btn(:color="btn1.color" x-large depressed block, :loading="btn1.loading", @click="handleSignIn") 
                | {{btn1.text}}
            v-btn(text block @click="showLogin = false").mt-2 Go back
            v-alert(v-if="error" type="error" outlined).mt-3 {{error}}
</template>

<script>
import { get, sync, call } from "vuex-pathify";
import { isValidEmail, isValidPassword } from "@/helpers.js";
const BUTTON_1_DEFAULT = {
  text: "Log in or Register",
  icon: "mdi-account",
  color: "primary",
  loading: false,
};

export default {
  name: "Registration",
  data() {
    return {
      btn1: JSON.parse(JSON.stringify(BUTTON_1_DEFAULT)),
      email: null,
      password: null,
      error: null,
      showLogin: false,
    };
  },
  computed: {
    ...get({ userData: "User/userData" }),
    ...sync({ subscriptionNeeded: "Nav/dialogs@subscriptionNeeded" }),
  },
  created() {
    this.error = null;
  },
  methods: {
    ...call({
      userAlreadyExists: "User/userAlreadyExists",
      createUser: "User/createUser",
      signIn: "User/signIn",
    }),
    async handleSignIn() {
      try {
        this.btn1.text = "Signing in...";
        this.btn1.icon = "mdi-loading";
        this.btn1.color = "accent";
        this.btn1.loading = true;

        this.error = null;
        window.debug("handling sign in...", this.email, this.password);
        if (!isValidEmail(this.email)) {
          this.error = "Please enter a valid email address first";
          return;
        } else if (!isValidPassword(this.password)) {
          this.error =
            "Create a password with at least 8 characters, and include a number";
          return;
        } else {
          window.debug("proceeding with login...");
          const userAlreadyExists = await this.userAlreadyExists({
            email: this.email,
          });

          window.debug("userAlreadyExists", userAlreadyExists);

          if (userAlreadyExists) {
            window.debug("user exists, so signing in...");
            await this.signIn({ email: this.email, password: this.password });
            this.subscriptionNeeded = false;
          } else {
            window.debug("user does not exist, so need to register");
            const userCreated = await this.createUser({
              email: this.email,
              password: this.password,
            });
            window.debug("user Created", userCreated);

            if (!userCreated)
              throw new Error("Failed to register the user for some reason");

            if (!this.userData) {
              throw new Error(
                "The user was registered, but they failed to be signed in automatically."
              );
            } else {
              await this.goToCheckoutIfNeeded();
              this.subscriptionNeeded = false;
            }
          }
        }
      } catch (err) {
        this.error = err;
      } finally {
        setTimeout(() => {
          this.btn1 = JSON.parse(JSON.stringify(BUTTON_1_DEFAULT));
        }, 2000);
      }
    },
  },
};
</script>
