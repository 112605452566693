<template lang="pug">
    .tools-menu
        v-menu(offset-y bottom open-on-hover)
          template(v-slot:activator="{ on, attrs }")
            v-btn(text v-on="on").btn.font-weight-bold
              v-icon.mr-2 mdi-television-play
              | Lessons
          v-list(max-width="400px")
            v-list-item-group(v-model="selectedItem")
              v-list-item(v-for="itm in items", :key="itm.to", :to="itm.to")
                .d-flex
                    .icon(:style="{'background-color': itm.bgColor, color: itm.color}") {{itm.icon}}
                    v-list-item-title {{itm.text}}

</template>

<script>
import { get, sync } from "vuex-pathify";
export default {
  name: "LessonsMenu",
  computed: {
    ...get({ shows: "Lessons/shows", userData: "User/userData" }),
    ...sync({ subscriptionNeeded: "Nav/dialogs@subscriptionNeeded" }),
  },
  created() {
    this.init();
  },
  data() {
    return {
      selectedItem: 1,
      items: [],
    };
  },
  methods: {
    init() {
      this.items = this.shows
        .map((itm) => {
          const to =
            "/lessons/" + itm.title.toLowerCase().trim().split(" ").join("_");
          return {
            text: itm.title,
            to,
            icon: itm.shortForm,
            bgColor: itm.colors.background,
            color: itm.colors.foreground,
          };
        })
        .filter(Boolean);
    },
    // getIcon(letter) {},
  },
};
</script>

<style lang="scss" scoped>
.icon {
  width: 32px;
  max-width: 32px;
  margin: auto;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 0.7rem;
  font-weight: bold;
  padding: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  letter-spacing: 1px;
}
</style>
