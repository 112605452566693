<template lang="pug">
      v-dialog(v-model="showCancelSubscription" max-width="850px" overlay-color="primary", overlay-opacity="0.95", :transition="false")
        template(v-if="loading")
          v-card.pa-5
            .title.mb-3 No prob, we are now canceling your subscription...
            v-skeleton-loader(type="card")
        
        template(v-else-if="result")
          template(v-if="result !== true")
            v-card.pa-5
              v-alert(type="error" border="left" outlined)
                p There was an error canceling your subscription automatically, so we'll have to take care of it by hand. Please email <a href='mailto:support@SeriCity.com'>support@SeriCity.com</a> or find us on Telegram: @SeriCity.
                p Please let us know the error message you received was:
                p.font-weight-bold
                  mark.yellow(:style="{'word-break': 'break-word'}") "{{result}}" LoremIpsumissimplydummytextoftheprintingandtypesettingindustry.LoremIpsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                p Don't worry, we'll refund you for a month if there's any delay on our end.
                v-btn(color="primary", depressed, @click="showCancelSubscription = false") Back to SeriCity
          template(v-else)
            v-card.pa-5(flat)
              h2.mb-3 Done! Your monthly subscription has been canceled.
              p You can keep using SeriCity until the end of your billing period, and then it'll switch to the free version. 
              p We hope you've enjoyed using SeriCity and we'll love to welcome you back.
              p If you have any feedback for how to improve the platform and make it more valuable for you, could you please email us at <a href='mailto:feedback@SeriCity.com'>feedback@SeriCity.com</a>?
              v-btn(color="primary", depressed, @click="showCancelSubscription = false") Back to SeriCity
        
        template(v-else)
          v-card.pa-5(flat color='white')
            .text-center.mb-3
              b Your Sericity subscription comes with benefits
            ul
              li(v-for="benefit in benefits.slice(0, -1)", :key="benefit", v-html="benefit") 
              li UNLIMITED access to ALL our lessons
              
            v-divider.my-3
            center
              b Are you sure you want to cancel your subscription?
              .buttons.mt-3
                v-btn(color="accent" depressed, @click="showCancelSubscription = false" :block="$vuetify.breakpoint.smAndDown").mb-3.mb-md-0
                  v-icon.mr-1 mdi-arrow-left
                  | No, go back
                v-btn(color="error" text, :block="$vuetify.breakpoint.smAndDown", @click="handleCancel") Yes, cancel subscription
              
                  

</template>

<script>
import { get, sync, call } from "vuex-pathify";
export default {
  name: "DialogCancelSubscription",
  components: {},
  watch: {
    showCancelSubscription() {
      this.init();
    },
    immediate: true,
  },
  data() {
    return { loading: false, result: null };
  },

  computed: {
    ...get({
      userData: "User/userData",
      benefits: "Billing/benefits",
    }),
    ...sync({ showCancelSubscription: "Nav/dialogs@showCancelSubscription" }),
  },
  methods: {
    ...call({ cancelSubscription: "Billing/cancelSubscription" }),
    init() {
      this.loading = false;
      this.result = null;
    },
    async handleCancel() {
      this.loading = true;
      this.result = await this.cancelSubscription();
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.benefit.small {
  display: inline-block;
}
</style>
