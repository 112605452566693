<template lang="pug">
  .toast(:style="{'z-index': 9999}")
    v-snackbar(v-if="message", v-model="show", :timeout="timeout", :color="color") 
        .d-flex.justify-space-between
          .message
            v-row(dense)
              v-col(cols="1")
                v-icon.mr-2 mdi-check-circle
              v-col(cols="11")
                span.font-weight-bold {{message}}
          v-btn(icon @click="show = false")
            v-icon mdi-close
</template>

<script>
import { get, sync } from "vuex-pathify";
export default {
  name: "Toast",
  props: {},
  computed: {
    ...get({
      message: "Nav/toast@message",
      color: "Nav/toast@color",
      timeout: "Nav/toast@timeout",
    }),
    ...sync({ show: "Nav/toast@show" }),
  },
};
</script>
