export const randomFromArray = (arr, num = 10) => {
  return [...arr].sort(() => 0.5 - Math.random()).slice(0, num);
};

export const chunkArray = (arr, n) => {
  const chunkLength = Math.max(arr.length / n, 1);
  const chunks = [];
  for (let i = 0; i < n; i++) {
    if (chunkLength * (i + 1) <= arr.length)
      chunks.push(arr.slice(chunkLength * i, chunkLength * (i + 1)));
  }
  return chunks;
};

export const copyToClipboard = (str, parentElem) => {
  /* ——— Derived from: https://hackernoon.com/copying-text-to-clipboard-with-javascript-df4d4988697f
         improved to add iOS device compatibility——— */
  const el = document.createElement("textarea"); // Create a <textarea> element

  const storeContentEditable = el.contentEditable;
  const storeReadOnly = el.readOnly;

  el.value = str; // Set its value to the string that you want copied
  el.contentEditable = true;
  el.readOnly = false;
  el.setAttribute("readonly", false); // Make it readonly false for iOS compatability
  el.setAttribute("contenteditable", true); // Make it editable for iOS
  el.style.position = "absolute";
  el.style.left = "-9999px"; // Move outside the screen to make it invisible

  const containerElem = parentElem || document.body;
  containerElem.appendChild(el);

  const selected =
    document.getSelection().rangeCount > 0 // Check if there is any content selected previously
      ? document.getSelection().getRangeAt(0) // Store selection if found
      : false; // Mark as false to know no selection existed before
  el.select(); // Select the <textarea> content
  el.setSelectionRange(0, 999999);
  document.execCommand("copy"); // Copy - only works as a result of a user action (e.g. click events)
  containerElem.removeChild(el); // Remove the <textarea> element
  if (selected) {
    // If a selection existed before copying
    document.getSelection().removeAllRanges(); // Unselect everything on the HTML document
    document.getSelection().addRange(selected); // Restore the original selection
  }

  el.contentEditable = storeContentEditable;
  el.readOnly = storeReadOnly;
};

export const isValidEmail = (str) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(str).toLowerCase());
};

export const isValidPassword = (str) => {
  const re = /^(?=.*[a-z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;
  return re.test(String(str));
};

export const scrollToTarget = (element, offset = 45) => {
  const bodyRect = document.body.getBoundingClientRect().top;
  const elementRect = element.getBoundingClientRect().top;
  const elementPosition = elementRect - bodyRect;
  const offsetPosition = elementPosition - offset;

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });
};

export const capitalize = (str, capitalizeAfterSpecialChars = false) => {
  if (!str || str.length < 1) return null;

  const v1 = str[0].toUpperCase() + str.substr(1, str.length - 1);
  if (!capitalizeAfterSpecialChars) return v1;

  const arr = v1.split("-");
  const combo = arr.map((itm) => capitalize(itm));
  return combo.join("-");
};

export const waitForElemToExist = (selector) => {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
};

export const shuffleArray = (array) => {
  let counter = JSON.parse(JSON.stringify(array)).length;

  // While there are elements in the array
  while (counter > 0) {
    // Pick a random index
    let index = Math.floor(Math.random() * counter);

    // Decrease counter by 1
    counter--;

    // And swap the last element with it
    let temp = array[counter];
    array[counter] = array[index];
    array[index] = temp;
  }

  return array;
};
