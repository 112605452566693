<template lang="pug">
  .billing-mini(:style="{'min-height':'250px'}").d-flex.flex-column.justify-space-between
      .top
        PriceBlock.text-center
        v-btn.billing(:loading="btn1.loading", x-large block :color="btn1.color" @click="handleSubscribe")
          | {{btn1.text}}
          v-icon.ml-2 {{btn1.icon}}
        v-btn(text block @click="$emit('click:dismiss')").mt-4 Do it later
      v-spacer
      .bottom
        .note.text-center.mt-3 You can cancel any time.<br> Full refund if you're not satisfied during your first week.
</template>

<script>
import { get, call } from "vuex-pathify";

import PriceBlock from "@/components/Billing/PriceBlock.vue";

const BUTTON_1_DEFAULT = {
  text: "Subscribe now",
  icon: "mdi-arrow-right",
  color: "primary",
  loading: false,
};

export default {
  name: "BillingMini",
  components: { PriceBlock },
  data() {
    return {
      btn1: JSON.parse(JSON.stringify(BUTTON_1_DEFAULT)),
    };
  },
  computed: {
    ...get({ userData: "User/userData" }),
  },
  methods: {
    ...call({
      goToCheckoutIfNeeded: "Billing/goToCheckoutIfNeeded",
    }),
    async handleSubscribe() {
      try {
        this.btn1.text = "Redirecting";
        this.btn1.icon = "mdi-loading";
        this.btn1.color = "warning";
        await this.goToCheckoutIfNeeded();
      } catch (err) {
        console.error(err);
      } finally {
        setTimeout(() => {
          this.btn1 = JSON.parse(JSON.stringify(BUTTON_1_DEFAULT));
        }, 2000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.note {
  font-size: 0.8rem;
  line-height: 1.25;
}
</style>
