<template lang="pug">
    .navbar
      //- TopBanner
      NavBarMobile(v-if="isMobile")
      NavBarDesktop(v-else)
            
</template>

<script>
import TopBanner from "@/components/Nav/TopBanner.vue";
import NavBarDesktop from "@/components/Nav/NavBarDesktop.vue";
import NavBarMobile from "@/components/Nav/NavBarMobile.vue";
import { get } from "vuex-pathify";
export default {
  name: "Navbar",
  components: { TopBanner, NavBarDesktop, NavBarMobile },
  computed: { ...get({ isMobile: "isMobile" }) },
};
</script>

<style lang="scss" scoped>
.btn {
  margin-right: 0.5rem;
}

@media only screen and (max-width: 600px) {
  .btn {
    margin-bottom: 0.5rem;
  }
}
</style>
