// import router from '@/router'
// import Firebase from "firebase/app";
import { make } from "vuex-pathify";

const state = {
  audioElem: null,
  audioIsPlaying: false,
  playPromise: undefined,
  activeSrc: null,
};
const getters = {
  ...make.getters(state),
};

const mutations = make.mutations(state);

const actions = {
  initAudio({ commit }) {
    const audio = document.getElementById("audioElem");
    audio.style.display = "none";

    audio.autoplay = false;
    audio.preload = "auto";

    commit("SET_AUDIO_ELEM", audio);
    audio.play();
  },
  async playAudio({ commit, getters }, payload) {
    try {
      const audio = getters.audioElem;

      commit("SET_AUDIO_IS_PLAYING", true);

      let isPlaying = true;

      // On video playing toggle values
      audio.onplaying = function () {
        isPlaying = true;
      };

      // On video pause toggle values
      audio.onpause = function () {
        isPlaying = false;
      };

      if (isPlaying) audio.pause();

      return await new Promise(function (resolve) {
        commit("SET_ACTIVE_SRC", payload);
        audio.src = payload;
        setTimeout(() => {
          audio.play();
        }, 500);

        audio.addEventListener("ended", function () {
          commit("SET_AUDIO_IS_PLAYING", false);
          commit("SET_ACTIVE_SRC", null);
          resolve();
        });
      });
    } catch (err) {
      console.error("Error while attempting to play audio", err);
    }
  },
  async playSystemAudio({ dispatch }, payload) {
    let path = "";
    if (payload === "success") path = "/sounds/success.mp3";
    else if (payload === "fail") path = "/sounds/fail.mp3";

    await dispatch("playAudio", path);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
