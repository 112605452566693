<template lang="pug">
    .user-menu.text-left.text-md-right.ml-4.ml-md-0.pb-7.pb-md-0
      DialogCancelSubscription
      template(v-if="userData && userData.email")
        v-menu(offset-y open-on-hover)
          template(v-slot:activator="{ on, attrs }")
            .text-center.text-md-right
              a.white--text(v-on="on", v-bind="attrs").d-inline-block
                span.text-truncate.d-inline-block(:style="{'max-width':'200px'}") {{userData.email}}
          v-list(max-width="400px")
            v-list-item()
              .d-flex.flex-column(v-if="userData.subscriptionWasCanceled", flat)
                  .primary--text.font-weight-bold.mb-3 Subscription was canceled
                  small.note You were on the Pro plan, but your monthly subscription was canceled. When it expires at the end of your billing cycle, you will be switched to the FREE plan.
              .d-flex.flex-column(v-else-if="userData.subscriptionIsActive", flat)
                  .primary--text.font-weight-bold.mb-3 Congrats!
                  .note You're on the Pro plan, which means you have access to every lesson we offer.
              .d-flex.flex-column(v-else).pa-4
                .warning--text.font-weight-bold.mb-3 Guest User
                .note You get the first couple episodes of any show for free, but after that you'll need to subscribe to see more lesson plans.
                v-btn(color="accent", depressed block @click="subscriptionNeeded = true").mt-3 Subscribe now
            v-list-item(v-if="userData.subscriptionIsActive && !userData.subscriptionWasCanceled" @click="showCancelSubscription = true")
                small.cancel.text--disabled You can 
                  a.text--disabled(href="#") cancel
                  |  our monthly subscription any time.
        
                

            .text-center
              v-btn(text color="error" block @click="logout") Log out
      template(v-else)
        .text-center.text-md-right
          v-btn.font-weight-bold(color="accent" text tile @click="subscriptionNeeded = true") Login
            v-icon.ml-2 mdi-login
</template>

<script>
import { get, sync, call } from "vuex-pathify";
import DialogCancelSubscription from "@/components/Dialogs/DialogCancelSubscription.vue";
export default {
  name: "UserMenu",
  components: { DialogCancelSubscription },
  computed: {
    ...get({ userData: "User/userData" }),
    ...sync({
      subscriptionNeeded: "Nav/dialogs@subscriptionNeeded",
      showCancelSubscription: "Nav/dialogs@showCancelSubscription",
    }),
  },
  methods: {
    ...call({ logout: "User/logout" }),
    handleListItemClick() {
      console.log("list item clicked");
    },
  },
};
</script>

<style lang="scss" scoped></style>
